import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

// Connects to data-controller="sortable"
export default class extends Controller {
  static targets = [
    'modal',
    'moveButton',
    'moveInput',
    'hideable',
    'number',
    'modalAppend',
    'moveToButton'
  ]
  connect () {
    const sortableContainerId = this.element.id
    const isDefault = this.element.dataset.isDefault === 'true'
    new Sortable(this.element, {
      group: {
        name: 'shared',
        put: !(sortableContainerId === 'left' && isDefault)
      },
      sort: sortableContainerId !== 'left',
      animation: 150,
      onAdd: this.onAdd.bind(this),
      onUpdate: this.onUpdate.bind(this),
      onRemove: this.onRemove.bind(this)
    })
  }

  getToken () {
    return document.getElementsByName('csrf-token')[0].content
  }

  fetchData (updatePath, body) {
    const token = this.getToken()
    fetch(updatePath, {
      method: 'PATCH',
      body: JSON.stringify({
        customer_group: body
      }),
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      }
    })
      .then(response => response.text())
      .then(html => {
        if (html.includes('Invalid position provided')) {
          window.location.reload();
        } else {
          Turbo.renderStreamMessage(html)
        }
      })
  }

  onAdd (event) {
    if (event.to.id === 'right' && event.from.id === 'left') {
      const updatePath = this.element.dataset.updateUrl
      const body = {
        customer_ids: [event.item.dataset.customerId]
      }

      this.fetchData(updatePath, body)
    }else{
      if(event.to.id === 'right' && event.from.id === 'right'){
        event.item.parentNode.removeChild(event.item);
        event.from.appendChild(event.item);
      }
    }
  }

  onRemove (event) {
    if (event.from.id === 'right' && event.to.id === 'left') {
      const updatePath = this.element.dataset.updateUrl
      const body = {
        remove_customer_ids: [event.item.dataset.customerId]
      }

      this.fetchData(updatePath, body)
    }
  }
  
  onUpdate (event) {
    const customerId = event.item.dataset.customerId
    const position = event.newIndex + 1
    this.updateCustomerPosition(customerId, position)
  }

  updateCustomerPosition (customerId, position) {
    const updatePath = this.element.dataset.updateUrl
    const body = {
      customer_positions: [
        {
          customer_id: customerId,
          position: position
        }
      ]
    }
    this.fetchData(updatePath, body)
  }

  updateMovePosition (event) {
    event.currentTarget.dataset.position = this.numberTarget.value
    this.updatePosition(event)
  }

  updatePosition (event) {
    const customerId =
      event.currentTarget.closest('#modalInsert').dataset.customerId
    const position = parseInt(event.currentTarget.dataset.position)
    this.updateCustomerPosition(customerId, position)
  }

  toggleModal (event) {
    this.modalTarget.classList.toggle('hidden')

    let modalElement = event.currentTarget.nextElementSibling
    let customerId = modalElement.dataset.customerId
    modalElement.appendChild(this.modalTarget)

    let numberField = this.numberTarget
    numberField.value = modalElement.dataset.position
    numberField.id = customerId

    let numberChangeButtons =
      numberField.parentNode.querySelectorAll('.change-number')
    numberChangeButtons.forEach(el => {
      el.dataset.id = customerId
    })
    this.updateElementsVisibility(true, false)
    this.toggleButtonsVisibility(false, true)
  }

  hideModal () {
    if (this.moveInputTarget.classList.contains('hidden')) {
      let modalTarget = this.modalTarget
      modalTarget.classList.add('hidden')
      this.modalTarget.parentNode.removeChild(modalTarget)
      this.modalAppendTarget.appendChild(modalTarget)

      this.moveInputTarget.classList.add('hidden')
    } else {
      this.updateElementsVisibility(true, false)
      this.toggleButtonsVisibility(false, true)
    }
  }

  showMoveInput () {
    this.updateElementsVisibility(false, true)
    this.toggleButtonsVisibility(true, false)
  }

  toggleButtonsVisibility (moveToButtonVisible, moveButtonVisible) {
    this.moveToButtonTarget.classList.toggle('hidden', moveToButtonVisible)
    this.moveButtonTarget.classList.toggle('hidden', moveButtonVisible)
  }

  updateElementsVisibility (moveInputVisible, hideableVisible) {
    this.moveInputTarget.classList.toggle('hidden', moveInputVisible)
    this.hideableTargets.forEach(el => {
      el.classList.toggle('hidden', hideableVisible)
    })
  }
}
