import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="reports"
export default class extends Controller {
  static targets = ['selected_action']
  connect () {
    this.updateFormAction()
  }

  updateFormAction () {
    let selectedAction = this.selected_actionTarget.value
    this.element.action = `/web/reports/${selectedAction}`
  }
}
