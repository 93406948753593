import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ['input', 'options', 'hiddenField']

  connect () {
    //this is for closing of dropdown box on outside click
    document.addEventListener('click', this.closeDropdown.bind(this))
  }

  toggleDropdown () {
    this.optionsTarget.classList.toggle('hidden')
  }

  selectOption (e) {
    const selectedValue = e.currentTarget.querySelector('.selected-value')
    this.inputTarget.textContent = selectedValue.textContent.trim()
    this.hiddenFieldTarget.value = selectedValue.dataset.value
    this.toggleDropdown()
  }

  closeDropdown (event) {
    if (!this.element.contains(event.target)) {
      this.optionsTarget.classList.add('hidden')
    }
  }
}
