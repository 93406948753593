import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="fetch-customer"
export default class extends Controller {
  connect () {
  }

  customerDetails (event) {
    const selectedCustomer = event.currentTarget.dataset.id
    if (selectedCustomer) {
      this.url = `/web/customers/${selectedCustomer}/details`
      fetch(this.url, {
        headers: {
          Accept: 'text/vnd.turbo-stream.html'
        }
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }

  clearFetchData(){
    this.element.querySelector('#customer_data').innerHTML = ''
  }
}
