import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fetch-agency-user"
export default class extends Controller {
  connect () {
    this.update()
  }
  
  update() {
    const selectedAgency = this.element.options[this.element.selectedIndex].value;
    this.url = `/sales/agencies/${selectedAgency}/users`;

    fetch(this.url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then((response) => response.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
