import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment-reminder"
export default class extends Controller {
  static targets = ['daysLabel',
                    'dateField',
                    'daysList',
                    'monthList',
                    'dailyButton',
                    'weekdaysButton',
                    'monthlyButton',
                    'checkedDays',
                    'checkedDate',
                    'dayOfMonth',
                    'alert']
  connect() {
    if (this.weekdaysButtonTarget.checked) {
      this.weekdays();
    } else if (this.monthlyButtonTarget.checked) {
      this.monthly();
    }
    else {
      this.dailyButtonTarget.checked=true;
      this.daily();
    }
  }

  daily() {
    this.daysListTarget.classList.add('hidden');
    this.monthListTarget.classList.add('hidden');
    const today = new Date(this.dateFieldTarget.value);
    const dates = this.generateDates(today, 1);
    this.daysLabelTarget.innerHTML = dates.join(', ');
    this.alertTarget.innerHTML = "";
  }

  weekdays() {
    this.daysListTarget.classList.remove('hidden');
    this.monthListTarget.classList.add('hidden');
    this.updateWeekDaysDates();
    this.alertTarget.innerHTML = "";
  }

  monthly() {
    this.daysListTarget.classList.add('hidden');
    this.monthListTarget.classList.remove('hidden');
    this.generateMonthDates();
    this.alertTarget.innerHTML = "";
  }

  generateDates(today, step) {
    return Array.from({ length: 4 }, (_, day) => {
      const date = new Date(today);
      date.setDate(today.getDate() + day * step);
      return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
    });
    this.alertTarget.innerHTML = "";
  }

  selectDate() {
    if (this.dailyButtonTarget.checked) {
      this.alertTarget.innerHTML = "";
      this.daily();
    } else if (this.weeklyButtonTarget.checked) {
      this.alertTarget.innerHTML = "";
      this.weekdays();
    } else {
      this.alertTarget.innerHTML = "";
      this.monthly();
    }
  }

  updateWeekDaysDates() {
    const start_date = this.dateFieldTarget.value;
    let days = []
    this.checkedDaysTargets.forEach(el => {
      if (el.checked) {
        days.push(el.dataset.name)
      }
    });
    let result = this.getNextFourDates(days, start_date);
    this.daysLabelTarget.innerHTML = result.join(', ');
    this.alertTarget.innerHTML = "";
  }

  getNextFourDates(dayNames, start_date) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get current date
    let currentDate = new Date(start_date);
    currentDate.setDate(currentDate.getDate() + 1);

    let upcomingDates = [];
    let selectedDays = [];

    // Convert day names to their respective indices
    dayNames.forEach(dayName => {
        let inputDayIndex = daysOfWeek.indexOf(dayName);
        if (inputDayIndex !== -1) {
            selectedDays.push(inputDayIndex);
        }
    });

    // Calculate the number of dates per day
    let datesPerDay = Math.ceil(4 / selectedDays.length);

    // Loop through each selected day and calculate the upcoming dates
    selectedDays.forEach(inputDayIndex => {
        // Calculate the difference in days between the input day and the current day
        let dayDifference = inputDayIndex - currentDate.getDay();
        if (dayDifference < 0) {
            dayDifference += 7; // if the input day is earlier in the week, add 7 days to get the next occurrence
        }

        // Calculate the next 4 dates for this day
        for (let i = 0; i < datesPerDay; i++) {
            let upcomingDate = new Date(currentDate);
            upcomingDate.setDate(currentDate.getDate() + dayDifference + (i * 7)); // Add multiples of 7 to get subsequent occurrences
            upcomingDates.push(upcomingDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short' }));
        }
    });

    // Return only the first 4 dates if more than 4 dates were calculated
    return upcomingDates.sort().slice(0, 4);
  }

  generateMonthDates() {
    let selectedDays = [];
    const startDateStr = this.dateFieldTarget.value;
    this.checkedDateTargets.forEach(el => {
      if (el.checked) {
        selectedDays.push(el.dataset.name)
      }
    });
    const startDate = new Date(startDateStr);
    let result = this.generateNextFiveDates(selectedDays, startDate);

    result = this.sortAndFilterResult(result, startDate);
    result = this.generateDateAndMonthSTR(result);
    this.daysLabelTarget.innerHTML = result.join(', ');
    this.alertTarget.innerHTML = "";
  }

  generateNextFiveDates(selectedDays, startDate){
    const currentMonth = startDate.getMonth();
    const currentYear = startDate.getFullYear();
    let result = [];

    selectedDays.forEach(selectedDay => {
      for (let i = 0; i < 5; i++) {
        const month = (currentMonth + i) % 12;
        const year = currentYear + Math.floor((currentMonth + i) / 12);
        const lastDayOfMonth = new Date(year, month+1, 0).getDate();
        if (parseInt(selectedDay) <= lastDayOfMonth) {
          const date = new Date(year, month, selectedDay);
          result.push({date: date});
        }
      }
    });
    return result;
  }

  sortAndFilterResult(result, startDate) {
    startDate.setHours(0, 0, 0, 0);
    result = result.filter(date => date.date >= startDate);

    result.sort(function(a,b){
      return new Date(a.date)-new Date(b.date)
    });
    return result;
  }

  generateDateAndMonthSTR(result) {

    // Array to hold month names
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Iterate through the dates array and extract date and month names
    const formattedDatesArray = result
    .filter((item, index, self) =>
        index === self.findIndex(obj => obj.date.getTime() === item.date.getTime())
    )
    .slice(0, 4)
    .map(item => {
        const date = item.date.getDate();
        const month = monthNames[item.date.getMonth()];
        return `${date} ${month}`;
    });
    return formattedDatesArray;
  }
  validate(event) {
    let daysOfWeekTargets = this.checkedDaysTargets;
    let daysOfMonthTargets = this.checkedDateTargets;
    if (this.weekdaysButtonTarget.checked) {
      if (daysOfWeekTargets.some(element => element.checked) == false) {
        this.notifyCustomer("please select any day");
        event.preventDefault();
      }
    } else if(this.monthlyButtonTarget.checked) {
      if (daysOfMonthTargets.some(element => element.checked) == false) {
        this.notifyCustomer("please select any date");
        event.preventDefault();
      }
    }
  }

  notifyCustomer(msg) {
    this.alertTarget.innerHTML = msg;
  }
}
