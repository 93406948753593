import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="request-reports"
export default class extends Controller {
  static targets = ['reportType', 'notice']
  connect () {
    this.updateText()
  }

  updateText () {
    const selectedReport =
      this.reportTypeTarget.options[this.reportTypeTarget.selectedIndex].value

    switch (selectedReport) {
      case 'invoice_summary':
        this.noticeTarget.textContent =
          'You will get all the products delivery and amount summary for a specific month.'
        break

      case 'invoice_detail':
        this.noticeTarget.textContent =
          'You will get all the products delivery and amount details for a specific month. The report may go into two pages.'
        break

      case 'invoice_summary_v2':
        this.noticeTarget.textContent =
          'You will get all the products delivery and amount details for a specific month in a single page.'
        break

      case 'invoice_summary_v3':
        this.noticeTarget.textContent =
          'You will get all the products delivery and amount summary for a specific month. This report will look different than the invoice summary report.'
        break

      case 'four_invoice_summaries_in_one_page':
        this.noticeTarget.textContent =
          'You will get four invoice summaries in one page without product details.'
        break

      case 'four_invoice_summaries_in_one_page_v2':
        this.noticeTarget.textContent =
          'You will get four invoice summaries in one page with product details. Only a maximum of two product details will be displayed due to space constraints.'
        break

      case 'customer_monthly_transaction_report':
        this.noticeTarget.textContent =
          'A monthly report is a summary of your business activities during a specific month.'
        break

      case 'two_invoice_summaries_in_one_page':
          this.noticeTarget.textContent =
            'You will get two invoice summaries in one page without product details.'
        break
      default:this.noticeTarget.textContent=""
    }
  }
}
