import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="products"
export default class extends Controller {
  static targets = ['hideable', 'up_arrow_hideable', 'down_arrow_hideable', 'div_hideable']

  connect () {
    this.hideableTargets.forEach(el => {
      if (el.dataset.initialHidden == 'true') {
        el.hidden = true
        el.classList.add('hidden')
      } else {
        el.hidden = false
        el.classList.remove('hidden')
      }
    })

    this.div_hideableTargets.forEach(el => {
      if (el.dataset.initialHidden == 'false') {
        el.hidden = false
        el.classList.remove('hidden')
        this.down_arrow_hideableTarget.classList.remove('hidden')
        this.up_arrow_hideableTarget.classList.add('hidden')
      }
    })
  }

  showTargets () {
    this.hideableTargets.forEach(el => {
      el.hidden = false
      el.classList.remove('hidden')
    })
  }

  hideTargets () {
    this.hideableTargets.forEach(el => {
      el.hidden = true
      el.classList.add('hidden')
    })
  }

  toggleTargets () {
    console.log('-------------clicked for toggle')
    this.hideableTargets.forEach(el => {
      el.hidden = !el.hidden
      if (el.hidden) {
        el.classList.add('hidden')
      } else {
        el.classList.remove('hidden')
      }
    })
  }

  toggleArrowTargets () {
    this.div_hideableTargets.forEach(el => {
      el.hidden = !el.hidden
      if (el.hidden) {
        el.classList.add('hidden')
        this.up_arrow_hideableTarget.classList.remove('hidden')
        this.down_arrow_hideableTarget.classList.add('hidden')
      } else {
        el.classList.remove('hidden')
        this.down_arrow_hideableTarget.classList.remove('hidden')
        this.up_arrow_hideableTarget.classList.add('hidden')
      }
    })
  }
}
