import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  submit(event) {
    event.preventDefault();

    const form = this.element;
    const confirmBox = document.getElementById("custom-confirm-box");
    const productNameInput = form.querySelector("#product_name");
    const productRateInput = form.querySelector("#product_rate");
    const returnableCheckbox = form.querySelector("#product_returnable");
    const productName = this.titleize(productNameInput.value);
    const productRate = parseFloat(productRateInput.value).toFixed(1);
    const isReturnable = returnableCheckbox.checked;
    const receiveProduct = confirmBox.querySelector("#receive_product");
    const productText = this.generateProductText(productName, isReturnable);

    this.showProductPopup();
    this.showConfirmationDialog(confirmBox, productName, productRate, productText, isReturnable, receiveProduct, form);
  }
  showProductPopup() {
    document.getElementById("product_popup1").setAttribute("open", true);
  }

  showConfirmationDialog(confirmBox, productName, productRate, productText, isReturnable, receiveProduct, form) {
    confirmBox.classList.remove("hidden")
    this.toggleReturnableClass(receiveProduct, isReturnable);
    this.updateConfirmationContent(confirmBox, productName, productRate, productText);
    this.addConfirmationEventListeners(confirmBox, form);
  }

  toggleReturnableClass(receiveProduct, isReturnable) {
    receiveProduct.classList.toggle("hidden", !isReturnable);
  }

  updateConfirmationContent(confirmBox, productName, productRate, productText) {
    confirmBox.querySelector("#product_name").textContent = productName;
    confirmBox.querySelector("#product_rate").textContent = `Rate: ${productRate}/-`;
    confirmBox.querySelector("#product_text").textContent = productText;
  }

  addConfirmationEventListeners(confirmBox, form) {
    confirmBox.addEventListener("confirmed", () => {
      Turbo.navigator.submitForm(form);
    });

    confirmBox.addEventListener("canceled", () => {
      confirmBox.classList.add("hidden");
    });
  }

  generateProductText(productName, isReturnable) {
    const returnabilityStatus = isReturnable ? "returnable" : "non-returnable";
    const returnabilityOption = isReturnable ? "will" : "won't";
    const anyOrAn = isReturnable ? "an" : "any";

    return `The ${productName} will be categorized as a ${returnabilityStatus} product, and you ${returnabilityOption} find ${anyOrAn} option to receive empty containers of this product on every delivery screens.`;
  }

  confirm() {
    this.hideConfirmBox();
    this.dispatchConfirmationEvent("confirmed");
  }

  cancel() {
    this.hideConfirmBox();
    this.dispatchConfirmationEvent("canceled");
  }

  hideConfirmBox() {
    this.element.classList.add('hidden')
  }

  dispatchConfirmationEvent(eventName) {
    this.element.dispatchEvent(new CustomEvent(eventName));
  }

  titleize(str) {
    return str.toLowerCase().replace(/(?:^|\s|-)\w/g, (match) => match.toUpperCase());
  }
}
