import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="employee-payments"
export default class extends Controller {
  static targets = ['currentUser', 'userField', 'transactionTypeText']
  connect(){
    this.updateUser()
  }
  updateUser () {
    const transactionType = this.element.querySelector('input[type="radio"]:checked').value
    const isDeposit = transactionType === 'deposit'
    this.currentUserTarget.textContent = isDeposit
      ? this.userFieldTarget.dataset.currentUserName
      : ''
    this.userFieldTarget.classList.toggle('hidden', isDeposit)
    this.currentUserTarget.classList.toggle('hidden', !isDeposit)
    
    if(isDeposit){
     this.transactionTypeTextTarget.textContent = "Deposit money to self"
    }else if (transactionType === 'receive'){
      this.transactionTypeTextTarget.textContent = "Payment received from employee"
    }else{
      this.transactionTypeTextTarget.textContent = "Payment give to employee"
    }
  }
}
