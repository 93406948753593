import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="opening-delivery"
export default class extends Controller {
  static targets = ['dateField', 'quantity', 'alert']
  connect() {
    document.addEventListener("click", this.handleClick.bind(this));
  }
  updateScheduleDeliveryTransactionDate(event) {
    const dateString = event.target.value;
    if(this.isValidDate(dateString)) {
      this.dateFieldTargets.forEach(el => {
        el.value = dateString;
      })
    }
  }

  handleClick(event) {
    if (event.target.dataset.action != 'click->opening-delivery#validate') {
      this.alertTarget.innerHTML =""
    }
  }

  isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) && date.toISOString().slice(0, 10) === dateString;
  }

  incQty(event){
    const targetId = event.target.dataset.targetId;
    const qtyTargets = this.quantityTargets;
    this.incValue(qtyTargets, targetId)
  }

  decQty(event){
    const targetId = event.target.dataset.targetId;
    const qtyTargets = this.quantityTargets;
    this.decValue(qtyTargets, targetId)
  }

  incValue(target, targetId){
    target.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.value = parseInt(el.value)+1;
      }
    })
  }

  decValue(target, targetId){
    target.forEach(el => {
      if(el.dataset.targetId == targetId && parseInt(el.value)>0) {
        el.value = (parseFloat(el.value)-1);
      }
    })
  }

  validate(event) {
    let quantityTargets = this.quantityTargets;
    if (quantityTargets.every(element => element.value == 0)) {
      this.notifyCustomer("please Deliver at least one product");
      event.preventDefault();
    }
  }

  notifyCustomer(msg) {
    this.alertTarget.innerHTML = msg;
  }
}
