import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fetch-group"
export default class extends Controller {
  connect() {
  }

  groupCustomers () {
    const selectedGroup = this.element.options[this.element.selectedIndex].value
    if (selectedGroup) {
      this.url = `/web/groups/${selectedGroup}/customers`
      fetch(this.url, {
        headers: {
          Accept: 'text/vnd.turbo-stream.html'
        }
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }
}
