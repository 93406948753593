import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="amount-validator"
export default class extends Controller {
  static targets = ['amountField', 'alert']

  validateAmount(event) {
    const amount = parseInt(this.amountFieldTarget.value);
    const fieldName = this.amountFieldTarget.dataset.fieldName;
    let alertTarget = this.alertTarget;

    if (isNaN(amount)){
      this.amountFieldTarget.classList.add("border-red-600");
      alertTarget.innerHTML = `${fieldName} can't be blank.`
      event.preventDefault();
    }
    else if (amount <=0 ) {
      this.amountFieldTarget.classList.add("border-red-600");
      alertTarget.innerHTML = `${fieldName} must be greater than zero.`
      event.preventDefault();
    } else {
      alertTarget.innerHTML = ""
      this.amountFieldTarget.classList.remove("border-red-600");
    }
  }
}
