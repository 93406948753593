import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-permission"
export default class extends Controller {
  static targets = ['allowedCreateBackDays',
                    'allowedUpdateBackDays',
                    'createCheckBox',
                    'updateCheckBox',
                    'createInputBox',
                    'updateInputBox',
                    'createPermissionAlert',
                    'updatePermissionAlert',
                    'qrCodeDelivery',
                    'manualDelivery'];
  connect() {
    this.create();
    this.update();
  }
  create() {
    const checkbox = this.createCheckBoxTarget;
    const backDaysTarget = this.allowedCreateBackDaysTarget;
    const labelTarget = this.createPermissionAlertTarget;
    if (checkbox.checked) {
      backDaysTarget.classList.remove('hidden');
      this.createPermissionAlertTarget.classList.remove('hidden');
      this.showQrAndManualDeliveryCheckbox();
      this.showDates(labelTarget, 'create')
    } else {
      backDaysTarget.classList.add('hidden');
      this.createPermissionAlertTarget.classList.add('hidden');
      this.hideQrAndManualDeliveryCheckbox();
    }
  }
  update() {
    const checkbox = this.updateCheckBoxTarget;
    const labelTarget = this.updatePermissionAlertTarget;
    const backDaysTarget = this.allowedUpdateBackDaysTarget;
    if (checkbox.checked) {
      backDaysTarget.classList.remove('hidden');
      this.updatePermissionAlertTarget.classList.remove('hidden');
      this.showDates(labelTarget, 'update');
      this.showQrAndManualDeliveryCheckbox();
    } else {
      backDaysTarget.classList.add('hidden');
      this.updatePermissionAlertTarget.classList.add('hidden');
      this.hideQrAndManualDeliveryCheckbox();
    }
  }
  createIncrement() {
    const inputTarget = this.createInputBoxTarget;
    if (parseInt(inputTarget.value) < 90) {
      inputTarget.stepUp();
      const labelTarget = this.createPermissionAlertTarget;
      this.showDates(labelTarget, 'create');
    }
  }
  createDecrement(){
    this.createInputBoxTarget.stepDown();
    const labelTarget = this.createPermissionAlertTarget;
    this.showDates(labelTarget, 'create');
  }
  updateIncrement() {
    const inputTarget = this.updateInputBoxTarget;
    if (parseInt(inputTarget.value) < 90) {
      inputTarget.stepUp();
      const labelTarget = this.updatePermissionAlertTarget;
      this.showDates(labelTarget, 'update');
    }
  }
  updateDecrement(){
    this.updateInputBoxTarget.stepDown();
    const labelTarget = this.updatePermissionAlertTarget;
    this.showDates(labelTarget, 'update');
  }

  showDates(target, action){
    if (action == 'create') {
      var days = parseInt(this.createInputBoxTarget.value);
    } else {
      var days = parseInt(this.updateInputBoxTarget.value);
    }
    const todaysDate = this.formattedDate(new Date);
    const startDate = this.formattedDate(new Date(Date.now() - days * 24 * 60 * 60 * 1000));
    this.updateLabelText(target, action, startDate, todaysDate );
  }

  formattedDate(date){
   return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  updateLabelText(target, action, startDate, todaysDate ){
    target.innerHTML = `Allowed to ${action} deliveries entries from ${startDate} to ${todaysDate}`
  }

  updateDateLabel(event) {
    const backDays = event.target.value;
    if (backDays <= 90) {
      const actionType = event.target.dataset.actionName;
      const labelTarget = actionType == "create" ? this.createPermissionAlertTarget : this.updatePermissionAlertTarget;

      const todaysDate = this.formattedDate(new Date);
      const startDate = this.formattedDate(new Date(Date.now() - backDays * 24 * 60 * 60 * 1000));
      this.updateLabelText(labelTarget, actionType, startDate, todaysDate);
    }
  }

  showQrAndManualDeliveryCheckbox() {
    this.qrCodeDeliveryTarget.classList.remove('hidden');
    this.manualDeliveryTarget.classList.remove('hidden');
  }

  hideQrAndManualDeliveryCheckbox() {
    if (!this.createCheckBoxTarget.checked && !this.updateCheckBoxTarget.checked) {
      this.qrCodeDeliveryTarget.classList.add('hidden');
      this.manualDeliveryTarget.classList.add('hidden');
    }
  }

  validate(event){
    const parentContainer = event.currentTarget.closest('.parent-container');
    const inputFields = parentContainer.querySelectorAll('input');
    const actionName = event.target.name.slice(-5,-1);
    if (actionName == "read" && !event.target.checked) {
      inputFields.forEach(el=>{
        if (el.type == "checkbox") {
          el.checked=false;
        }
      })
      this.create();
      this.update();
    } else if(actionName != "read") {
      for (let i = 0; i < inputFields.length; i++) {
        let el = inputFields[i];
        if (el.type == "checkbox" && event.target.checked) {
          el.checked = true;
          return;
        }
      }
    }
  }

  validateDeliveryPermission(event){
    const createCheckbox = this.createCheckBoxTarget;
    const updateCheckbox = this.updateCheckBoxTarget;
    const qrCheckBox = this.element.querySelector('#user_jar_transaction_by_qr_code');
    const manualCheckBox = this.element.querySelector('#user_jar_transaction_by_manually');

    if ((createCheckbox.checked || updateCheckbox.checked) && !(qrCheckBox.checked || manualCheckBox.checked)) {
      window.alert("Enable QR code or manual delivery permissions for create or update jar transaction permissions.");
      event.preventDefault();
    }

  }
}
