import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expence"
export default class extends Controller {
  static targets = ['expence_date', 'submit_btn']

  validateDate(event) {
    const expenceDate = new Date(this.expence_dateTarget.value);
    expenceDate.setHours(0, 0, 0, 0);

    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);

    this.validateFutureDate(expenceDate, todaysDate);
  }

  validateFutureDate(expenceDate, todaysDate) {
    if (todaysDate < expenceDate) {
      this.expence_dateTarget.classList.add("border-red-500");
      this.disableButton();
    } else {
      this.enableButton();
      this.expence_dateTarget.classList.remove("border-red-500");
    }
  }

  enableButton() {
    this.submit_btnTarget.removeAttribute('disabled');
  }

  disableButton() {
    this.submit_btnTarget.setAttribute('disabled', 'disabled');
  }
}