import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="event"
export default class extends Controller {
  static targets = [
    'mobileNo',
    'address',
    'existCust',
    'newCust',
    'nameField',
    'customerType'
  ]
  connect () {}

  updateCustomerDetails(event) {
    const { mobile, address, name } = event.currentTarget.dataset
    this.setValues(mobile, address, name)
  }

  checkCustomerType() {
    const customerType =
      this.customerTypeTarget.options[this.customerTypeTarget.selectedIndex]
        .value
    if (customerType === 'new') {
      this.removeSelectedCustomer()
      this.toggleVisibility(true, false)
      this.setValues()
      this.nameFieldTarget.required=true
    } else {
      this.nameFieldTarget.required=false
      this.setValues()
      this.toggleVisibility(false, true)
    }
  }

  setValues(mobile = '', address = '', name = '') {
    this.mobileNoTarget.value = mobile
    this.addressTarget.value = address
    this.nameFieldTarget.value = name
  }

  toggleVisibility(existCust, newCust) {
    this.existCustTarget.classList.toggle('hidden', existCust)
    this.newCustTarget.classList.toggle('hidden', newCust)
  }

  removeSelectedCustomer(){
    this.element.querySelector("#event_customer_customer_id").value = ""
    this.element.querySelector("#selected_customer_id").innerHTML = 'Select an option...'
    const elements = this.element.querySelectorAll('[data-js="check"]');
    elements.forEach(element => {
      element.classList.add('hidden');
    });
  }
}
