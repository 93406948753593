import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="opening-amount"
export default class extends Controller {
  static targets = ['advanceAmountForm', 'dueAmountForm', 'advanceButton', 'dueButton']

  connect () {
    if (this.advanceButtonTarget.checked) {
      this.showAdvanceAmountForm();
    } else {
      this.showDueAmountForm();
    }
  }

  showDueAmountForm() {
    this.dueAmountFormTarget.classList.remove("hidden");
    this.advanceAmountFormTarget.classList.add("hidden");
  }

  showAdvanceAmountForm() {
    this.advanceAmountFormTarget.classList.remove("hidden");
    this.dueAmountFormTarget.classList.add("hidden");
  }

}
