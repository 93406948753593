import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="event-jar-booking"
export default class extends Controller {
  static targets = [
    "selectProduct",
    "productId",
    "removeProduct",
    "deleteButton",
  ];
  connect() { }

  fetchProduct() {
    const selectedProduct =
      this.selectProductTarget.options[this.selectProductTarget.selectedIndex]
        .value;
    const eventId = this.selectProductTarget.dataset.eventId;
    this.selectProductTarget.selectedIndex = 0;

    const productAlreadyPresent = this.productIdTargets.some(
      (el) => el.value === selectedProduct
    );

    if (productAlreadyPresent) {
      this.showErrorMessage("Product is already exist in list");
    } else {
      const alertMessage = this.element.querySelector("#product-alert");
      alertMessage.innerHTML = "";
      alertMessage.classList.remove("alert", "bg-gray-700", "text-white");
      if (selectedProduct) {
        this.url = `/web/events/${eventId}/event_product_booking_summaries/product?id=${selectedProduct}`;
        this.fetchAndUpdate(this.url);
      }
    }
  }

  removeProduct(event) {
    const productName = event.currentTarget.dataset.productname;
    if (
      confirm(
        `Are you sure?\nFrom this product booking "${productName}" will be deleted!`
      )
    ) {
      const targetId = event.currentTarget.dataset.id;
      const targetIndex = event.currentTarget.dataset.index;
      const id = `event_product_booking_summary_event_product_bookings_attributes_${targetIndex}_id`;
      const idField = this.element.querySelector(`#${id}`);
      const productCount = parseInt(event.currentTarget.dataset.productcount);
      if (productCount === 1) {
        this.showErrorMessage("Every booking should have at least one Product");
        event.preventDefault();
      } else {
        this.removeElements(idField, this.deleteButtonTargets, targetId);
      }
    } else {
      event.preventDefault();
    }
  }

  validateFields(event) {
    const requiredAt = this.element.querySelector(
      "#event_product_booking_summary_required_at"
    );
    if (requiredAt.value === "") {
      this.element.querySelector("#requiredAt-field-error").innerHTML =
        "Booking date-time can't be blank";
      event.preventDefault();
    } else {
      const productsNode = this.element.querySelector("#event_products");
      if (productsNode.childElementCount == 0) {
        this.showErrorMessage("Product list can't be empty");
        event.preventDefault();
      }
    }
  }

  clearErrorMessage() {
    this.element.querySelector("#requiredAt-field-error").innerHTML = "";
  }

  showErrorMessage(message) {
    const alertMessage = this.element.querySelector("#product-alert");
    alertMessage.classList.add("alert", "bg-gray-700", "text-white");
    alertMessage.innerHTML = `<span class="text-center">${message}</span>`;
    alertMessage.animate(
      [ { opacity: 1 }, { opacity: 0 } ],
      { duration: 4000 }
    ).onfinish = () => {
      alertMessage.innerHTML = "";
      alertMessage.classList.remove("alert", "bg-gray-700", "text-white");
    }
  }
  fetchAndUpdate(url) {
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  removeElements(idField, deleteButtonTargets, targetId) {
    if (idField) {
      idField.remove();
      deleteButtonTargets.forEach((el) => {
        el.dataset.productcount = el.dataset.productcount - 1;
      });
    }else
    {
      this.removeProductTargets.find((el) => el.id === targetId)?.remove();
    }
  }
}
