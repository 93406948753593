import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="removals"
export default class extends Controller {
  timeoutId = null; // to store the timeout ID

  connect () {
    this.timeoutId =  setTimeout(() => {
      this.fadeout()
    }, 2500)
  }
  fadeout () {
    this.element.remove()
  }

  disconnect() {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId); 
    }
  }
}
