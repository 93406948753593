import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="schedule-delivery"
export default class extends Controller {
  static targets = ['daysLabel', 'dateField', 'daysList', 'dailyButton', 'alternateButton', 'weekdaysButton', 'checkedDays','recurringType', 'dayOfWeekAlert']
  connect() {
    if(this.dailyButtonTarget.checked) {
      this.daily({ target: { dataset: { targetId: this.dailyButtonTarget.dataset.targetId } } });
    } else if(this.alternateButtonTarget.checked) {
      this.alternate({ target: { dataset: { targetId: this.alternateButtonTarget.dataset.targetId } } });
    } else {
      this.weekdays({ target: { dataset: { targetId: this.weekdaysButtonTarget.dataset.targetId } } });
    }
  }

  daily(event) {
    let targetId = event.target.dataset.targetId;
    this.daysListTarget.classList.add('hidden');
    const today = new Date(this.dateFieldTarget.value);
    const dates = this.generateDates(today, 1);
    this.daysLabelTarget.innerHTML = dates.join(', ');
    this.updateDeliveryRecurringFormAttributes(targetId, 'daily');
  }

  alternate(event) {
    let targetId = event.target.dataset.targetId;
    this.daysListTarget.classList.add('hidden');
    const today = new Date(this.dateFieldTarget.value);
    const dates = this.generateDates(today, 2);
    this.daysLabelTarget.innerHTML = dates.join(', ');
    this.updateDeliveryRecurringFormAttributes(targetId, 'alternate');
  }

  weekdays(event) {
    let targetId = event.target.dataset.targetId;
    this.daysListTarget.classList.remove('hidden');
    this.updateDates({ target: { dataset: { targetId: this.dailyButtonTarget.dataset.targetId } } });
    this.updateDeliveryRecurringFormAttributes(targetId, 'weekly');
  }

  generateDates(today, step) {
    return Array.from({ length: 4 }, (_, day) => {
      const date = new Date(today);
      date.setDate(today.getDate() + day * step);
      return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
    });
  }

  selectDate() {
    if (this.dailyButtonTarget.checked) {
      this.daily({ target: { dataset: { targetId: this.dailyButtonTarget.dataset.targetId } } });
    } else if (this.alternateButtonTarget.checked) {
      this.alternate({ target: { dataset: { targetId: this.alternateButtonTarget.dataset.targetId } } });
    } else {
      this.updateDates({ target: { dataset: { targetId: this.dailyButtonTarget.dataset.targetId } } });
    }
  }

  updateDates(event) {
    const start_date = this.dateFieldTarget.value;
    let days = []
    this.checkedDaysTargets.forEach(el => {
      if (el.checked) {
        days.push(el.dataset.name)
      }
    });
    let result = this.getNextFourDates(days, start_date);
    this.daysLabelTarget.innerHTML = result.join(', ');
    this.hideAlertLabel(event.target.dataset.targetId);
  }

  getNextFourDates(dayNames, start_date) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get current date
    let currentDate = new Date(start_date);
    currentDate.setDate(currentDate.getDate() + 1);

    let upcomingDates = [];
    let selectedDays = [];

    // Convert day names to their respective indices
    dayNames.forEach(dayName => {
        let inputDayIndex = daysOfWeek.indexOf(dayName);
        if (inputDayIndex !== -1) {
            selectedDays.push(inputDayIndex);
        }
    });

    // Calculate the number of dates per day
    let datesPerDay = Math.ceil(4 / selectedDays.length);

    // Loop through each selected day and calculate the upcoming dates
    selectedDays.forEach(inputDayIndex => {
        // Calculate the difference in days between the input day and the current day
        let dayDifference = inputDayIndex - currentDate.getDay();
        if (dayDifference < 0) {
            dayDifference += 7; // if the input day is earlier in the week, add 7 days to get the next occurrence
        }

        // Calculate the next 4 dates for this day
        for (let i = 0; i < datesPerDay; i++) {
            let upcomingDate = new Date(currentDate);
            upcomingDate.setDate(currentDate.getDate() + dayDifference + (i * 7)); // Add multiples of 7 to get subsequent occurrences
            upcomingDates.push(upcomingDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short' }));
        }
    });

    // Return only the first 4 dates if more than 4 dates were calculated
    return upcomingDates.sort().slice(0, 4);
  }

  updateDeliveryRecurringFormAttributes(targetId, value) {
    let recurringTypeTargets = this.recurringTypeTargets;
    recurringTypeTargets.forEach(el => {
      if(el.dataset.targetId == targetId) {
        el.value = value;
      }
    })
  }

  hideAlertLabel(targetId) {
    this.dayOfWeekAlertTargets.forEach(el=>{
      if (el.dataset.targetId == targetId) {
        el.innerHTML = "";
      }
    })
  }
}
