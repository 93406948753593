import { Controller } from '@hotwired/stimulus'
// Connects to data-controller="search-filter"
export default class extends Controller {
  static targets = ['item']
  connect () {
  }

  search (event) {
    const searchInput = event.target.value.toLowerCase()

    this.itemTargets.forEach(item => {
      const itemName = item
        .querySelector('.item-name')
        .textContent.toLowerCase()

      if (itemName.includes(searchInput)) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    })
  }
}
