import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fetch-event-product-bookings"
export default class extends Controller {
  static targets=['selectedDateTime']
  connect() {
  }
  fetchBookings(){
    const selectedDateTime = this.selectedDateTimeTarget.options[this.selectedDateTimeTarget.selectedIndex].value
    const event_id=this.selectedDateTimeTarget.dataset.eventId;
    if (selectedDateTime) {
    this.url = `/web/events/${event_id}/event_product_transaction_summaries/product_transactions?datetime=${selectedDateTime}`
    fetch(this.url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
  }
}
