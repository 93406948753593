import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="verify-otp"
export default class extends Controller {
  static targets = ['verifyButton', 'resendButton', 'errorLabel']
  timeoutId = null; // Property to store the timeout ID

  connect() {
    this.disableResendButton();
    this.timeoutId = setTimeout(() => {
      this.enableResendButton();
    }, 15000);
  }

  validateLength(event) {
    if (event.target.value.length >= 6) {
      this.verifyButtonTarget.disabled = false;
      this.errorLabelTarget.innerHTML = "";
      event.target.classList.remove('border-red-500');
    } else {
      this.errorLabelTarget.innerHTML = "Please enter 6 digits OTP"
      event.target.classList.add('border-red-500');
      this.verifyButtonTarget.disabled = true;
    }
  }

  enableResendButton() {
    const link = this.resendButtonTarget;
    link.classList.remove("pointer-events-none", "opacity-50", "cursor-not-allowed");
    link.setAttribute("href", link.dataset.originalhref);
  }

  disableResendButton() {
    const link = this.resendButtonTarget;
    link.classList.add("pointer-events-none", "opacity-50", "cursor-not-allowed");
    link.removeAttribute("href");
  }

  disconnect() {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId); 
    }
  }
}
