import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hideable', 'closeButton', 'toggleButton']

  connect () {
    this.toggleVisibility(this.hideableTarget.dataset.initialHidden === 'true')
    document.addEventListener('click', this.handleOutsideClick.bind(this))
  }

  hideTargets () {
    this.toggleVisibility(true)
  }

  toggleTargets () {
    const isHidden = (this.hideableTarget.hidden = !this.hideableTarget.hidden)
    this.toggleVisibility(isHidden)
  }

  toggleVisibility (isHidden) {
    const { hideableTarget, toggleButtonTarget, closeButtonTarget } = this

    hideableTarget.hidden = isHidden
    hideableTarget.classList.toggle('hidden', isHidden)
    toggleButtonTarget.classList.toggle('hidden', !isHidden)
    closeButtonTarget.classList.toggle('hidden', isHidden)
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.hideTargets();
    }
  }
}
